import axios from 'axios'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'
import helpers from '@/helpers'
import get from 'lodash-es/get'
import {message} from '@/plugins/message.js'

/**
 * Upload .docx file to Remote Server
 * @param {*} file
 * @param {string} unitId
 * @param {function} onProgress
 * @returns List Question read from file
 */
function uploadDocxToServer({file, unitId, onProgress, mode = 'default'}) {
  const data = new FormData()
  const vendorId = vendor.state.vendor.vendorId
  data.append('file', file)
  data.append('vendorId', vendorId)
  data.append('unitId', unitId)
  data.append('mode', mode)
  const uploaderURL = 'https://ee.izteach.vn/api/v1/upload'
  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response.status === 500) {
        const messageErr = get(error, 'response.data.message', null)
        if (typeof messageErr === 'string' && messageErr.length) {
          message.error(messageErr)
        }
        return Promise.reject(error)
      }
    }
  )
  return axios.post(uploaderURL, data, {
    headers: {
      Authorization: auth.state.auth.accessToken,
    },
    onUploadProgress: (progressEvent) => {
      const percentage = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
      if (typeof onProgress === 'function') {
        onProgress({
          loaded: parseFloat(progressEvent.loaded),
          total: parseFloat(progressEvent.total),
          percent: parseFloat(percentage),
        })
      }
    },
  })
}

function handleQuestion(data = {}, typePostfix = '') {
  const config = {time: 1}
  if (data.Explain) {
    config.explain = data.Explain
    config.isShowExplain = true
  }
  const question = {
    id: data.Id,
    html: data.Content || '',
    answers: {},
    correct: '',
    type: helpers.TYPES_OF_QUESTIONS.SINGLE_CHOICE + typePostfix,
    config,
    factor: 1,
  }
  if (data.Correct && Array.isArray(data.Correct)) {
    if (data.Correct.length === 1) {
      question.correct = data.Correct[0]
      question.type = helpers.TYPES_OF_QUESTIONS.SINGLE_CHOICE + typePostfix
    } else if (data.Correct.length > 1) {
      question.correct = [...data.Correct]
      question.type = helpers.TYPES_OF_QUESTIONS.MULTIPLE_CHOICES + typePostfix
    }
    // handle FILL_BLANK case; ETA N/A
  }
  const listDataAnswers = data.Answers || []
  if (Array.isArray(listDataAnswers) && listDataAnswers.length) {
    for (let j = 0; j < listDataAnswers.length; j++) {
      const dataAnswer = listDataAnswers[j]
      question.answers[dataAnswer.Id] = {
        type: 'html',
        value: dataAnswer.Content,
        label: helpers.DEFAULT_LABELS[j],
        key: dataAnswer.Id,
      }
    }
  }
  return question
}

/**
 * Map data returned from remote server to IZ schema
 * @param {*} listData Questions returned from Remote Upload
 * @returns Questions with IZ schema
 */
function mapQuestionData(listData = []) {
  const listQuestion = []
  if (Array.isArray(listData) && listData.length) {
    for (let i = 0; i < listData.length; i++) {
      const data = listData[i]
      let question = {}
      if (data.Type === 'group') {
        const childQuestions = []
        for (let j = 0; j < data.Children.length; j++) {
          childQuestions.push(handleQuestion(data.Children[j], '-child'))
        }
        question = {
          id: data.Id,
          type: 'group',
          html: data.Content,
          questions: childQuestions.map((x) => x.id),
          factor: childQuestions.length,
          children: childQuestions,
          config: {
            time: 1,
          },
        }
      } else {
        question = handleQuestion(data)
      }
      listQuestion.push(question)
    }
  }
  return listQuestion
}

export default {
  uploadDocxToServer,
  mapQuestionData,
}
