<template>
  <v-app id="app">
    <transition
      appear
      enter-active-class="animated slideInUp"
      leave-active-class="animated fadeOut"
    >
      <router-view />
    </transition>
    <BaseMessage />
    <BaseConfirm />
    <BaseConfirmSupport />
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  methods: {
    ...mapActions({
      fetchVendor: "vendor/fetchVendor",
      getSettingsVendor: "vendor/getSettingsVendor",
      initAuthen: "auth/initAuthen",
    }),
    setThemeData() {
      const siteName =
        this.themeData && this.themeData.siteName ? this.themeData.siteName : "Example";
      const title = this.$route.meta.title || this.$route.name || "Admin";

      const titleTemplate = siteName
        ? `${siteName} - ${this.$vuetify.lang.t(title)}`
        : "Admin Example";
      document.title = titleTemplate;
      document.getElementById("favicon").href = this.$utils.convertURL(
        this.themeData ? this.themeData.favicon : ""
      );
    },
  },
  async created() {
    await this.fetchVendor();
    await this.initAuthen();
    await this.getSettingsVendor();
    this.setThemeData();
  },
  computed: {
    ...mapGetters({
      themeData: "vendor/getThemedata",
      currentLanguage: "vendor/getCurrentLanguage",
    }),
  },
  watch: {
    $route: {
      handler() {
        this.setThemeData();
      },
      immediate: true,
    },
    currentLanguage: {
      handler(val) {
        if (val) {
          this.$vuetify.lang.current = String(val);
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}
::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 7px;
}
</style>
