<template>
  <div>
    <v-dialog v-model="dialog" width="620px">
      <v-card>
        <v-card-title class="headline lighten-2" primary-titledialog
          >{{ $vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TITLE_POPUP') }}
          <v-spacer></v-spacer>
          <v-icon @click="closePopup()"> mdi-close </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-checkbox
              v-if="sentStudent"
              v-model="selected"
              :label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.SELECT_ALL_STUDENTS')"
            />
            <BaseAutocomplete
              v-if="!selected && sentStudent"
              v-model="receivers"
              :label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_PICK_STUDENT')"
              :placeholder="$vuetify.lang.t('$vuetify.USER_MANAGE.STUDENT_LIST.TITLE')"
              :items="students"
              item-text="user.name"
              item-value="user.id"
              :returnObject="false"
              multiple
              small-chips
              deletable-chips
              :rules="[$rules.minLength(1)]"
            />
            <BaseInput
              class="mt-2"
              :label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.NOTIFICATION_TITLE_INPUT')"
              v-model="title"
              :rules="[$rules.required]"
            />
            <BaseTextArea
              class="mt-2"
              v-model="notification"
              :label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.SHORT_CONTENT_INPUT')"
              :row="2"
              :rules="[$rules.required]"
            />
            <BaseInput
              class="mt-2"
              :label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LINK_WEB_INPUT')"
              v-model="linkWeb"
            />
            <BaseInput
              class="mt-2"
              :label="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LINK_MOBILE_APP_INPUT')"
              v-model="linkMobile"
            />
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <BaseButton
            outlined
            @click="importHtml"
            :text="$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_IMPORT_FILE_HTML')"
          />
          <v-spacer></v-spacer>
          <BaseButton
            @click="sendNotification"
            :text="
              !sentStudent
                ? `${$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.SEND_NOTIFI_TO')}`
                : selected
                ? $vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_SEND_ALL_GROUP')
                : `${$vuetify.lang.t('$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_SEND')} ${
                    receivers.length
                  } ${$vuetify.lang.t('$vuetify.USER_MANAGE.STUDENT_LIST.TITLE')}`
            "
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ErrorNotificationPopup type="notification" ref="error-notification" />
    <SentNotificationPopup type="notification" ref="send-notificaton" />
    <input @change="handleFileSelect" ref="input-html-file" type="file" accept=".html" style="display: none" />
  </div>
</template>
<script>
import SentNotificationPopup from '@/modules/Group/components/notification/SentNotificationPopup.vue'
import ErrorNotificationPopup from '@/modules/Group/components/notification/ErrorNotificationPopup.vue'
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    sentStudent: {
      type: Boolean,
      default: true,
    },
    studentIds: {
      type: Array,
    },
  },
  components: {
    SentNotificationPopup,
    ErrorNotificationPopup,
  },
  data() {
    return {
      title: '',
      receivers: [],
      notification: '',
      linkWeb: '',
      linkMobile: '',
      dialog: false,
      loading: false,
      isButton: true,
      selected: true,
      ishtml: false,
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/getAuthUser',
      group: 'group/getGroup',
      students: 'userGroup/getUserGroups',
    }),
  },
  methods: {
    ...mapActions({
      sendToGroup: 'notification/sendToGroup',
      sendToUser: 'notification/sendToUser',
    }),
    closePopup() {
      this.dialog = false
      this.title = ''
      this.notification = ''
      this.receivers = []
      this.linkWeb = ''
      this.linkMobile = ''
      this.selected = true
      this.$refs.form.resetValidation()
    },
    async sendNotification() {
      if (this.$refs.form.validate()) {
        this.$message.loading('Đang gửi thông báo ...', 1000 * 60)
        if (this.selected && this.sentStudent) {
          await this.sendToGroup({
            notification: {
              title: this.title,
            },
            html: this.ishtml ? this.notification : `<div>${this.notification}</div>`,
            relationId: this.group.id,
            relationType: 'group',
          })
        } else if (!this.sentStudent) {
          let studentIds = this.studentIds.map((item) => item.userId)
          await this.sendToUser({
            notification: {
              title: this.title,
            },
            html: this.ishtml ? this.notification : `<div>${this.notification}</div>`,
            userIds: studentIds,
            relationType: 'student',
          })
        } else {
          await this.sendToUser({
            notification: {
              title: this.title,
            },
            html: this.ishtml ? this.notification : `<div>${this.notification}</div>`,
            userIds: this.receivers,
            relationId: this.group.id,
            relationType: 'student',
          })
        }
        this.closePopup()
        this.$message.success(
          this.$vuetify.lang.t('$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_SEND_NOTIFICATION_SUCCESS_MESSAGE')
        )
      }
    },
    openPopup() {
      this.dialog = true
    },
    importHtml() {
      this.$refs['input-html-file'].click()
    },
    handleFileSelect(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        let data = e.target.result
        if (data) {
          this.notification = data
          this.ishtml = true
        }
      }
      reader.readAsText(file)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
}
</style>
