import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const userGroup = RepositoryFactory.get('userGroup')
const namespaced = true

const state = {
  userGroups: [],
  totalItems: 0,
  pageText: '',
  itemsPerPage: 10,
  loading: false,
}

const actions = {
  async fetchAllUserGroup(_, params) {
    const res = await userGroup.fetch(params)
    return res.data
  },
  async fetchUserGroup({commit}, params) {
    commit('setUserGroup', [])
    let countParams = {...params, _limit: null, _skip: null}
    const [totalItems, userGroups] = await Promise.all([
      userGroup.count(countParams),
      userGroup.fetch({...params, _populate: 'student'}),
    ])
    commit('setUserGroup', userGroups.data)
    commit('setTotalItem', totalItems.data)
  },
  setPageText({state, commit}, {page, itemsPerPage}) {
    const pageStart = (page - 1) * itemsPerPage + 1
    let pageStop = page * itemsPerPage
    pageStop = pageStop > state.totalItems || pageStop === -1 ? state.totalItems : pageStop
    let pageText = `${pageStart}-${pageStop} trên ${state.totalItems}`
    commit('setPageText', pageText)
  },
  async getUserGroupByPagination({state, dispatch}, {page, itemsPerPage, group}) {
    state.loading = true
    if (!page) page = 1
    if (!itemsPerPage) itemsPerPage = state.itemsPerPage
    let params = {
      _skip: (page - 1) * itemsPerPage,
      _limit: itemsPerPage == -1 ? null : itemsPerPage,
      group,
    }
    await dispatch('fetchUserGroup', params)
    await dispatch('setPageText', {page, itemsPerPage})
    state.loading = false
  },
}

const mutations = {
  setUserGroup(state, value) {
    return (state.userGroups = value)
  },
  setPageText(state, value) {
    return (state.pageText = value)
  },
  setTotalItem(state, value) {
    return (state.totalItems = value)
  },
  resetUserGroup(state) {
    return (state.userGroups = [])
  },
}

const getters = {
  getUserGroups: (state) => {
    return state.userGroups
  },
  getPageText: (state) => {
    return state.pageText
  },
  getTotalItem: (state) => {
    return state.totalItems
  },
  getLoading: (state) => {
    return state.loading
  },
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
