import {Auth} from '@/api/repository/authRepository.js'
import mimeTypes from 'mime-types'
import auth from '@/store/modules/auth'
import axios from 'axios'
import vuetify from '@/plugins/vuetify'
const CancelToken = axios.CancelToken

/**
 * Function upload file lên s3. sử dụng tus method để hỗ trợ upload và retry
 * @return: Promise
 */
async function uploadFile({
  file,
  link,
  onProgress,
  urlPostfix = '/resource/signed-url',
  onCancel
}) {
  const contentType = mimeTypes.lookup(file.name)
  if (contentType) {
    const params = {
      key: link,
      contentType,
    }
    let signedUrl = await Auth.getS3SignedToken(
      params,
      auth.state.auth.accessToken,
      urlPostfix
    )
    return await axios.put(signedUrl, file, {
      onUploadProgress: function (progressEvent) {
        let {loaded, total} = progressEvent
        onProgress({
          loaded,
          total,
          percent: (loaded / total) * 100,
        })
      },
      cancelToken: new CancelToken(function executor(canceler) {
        if (typeof onCancel === 'function') onCancel(canceler)
      }),
      headers: {
        'Content-Type': contentType,
      },
    })
  } else
    throw new Error(
      vuetify.framework.lang.translator(
        '$vuetify.INPUT.MSG_CHECK_FILE_FORMAT_AGAIN'
      )
    )
}

async function uploadImageInBase64Str(base64str, link, onProgress) {
  const contentType = 'image/jpeg'
  const params = {
    key: link,
    contentType,
  }
  const signedUrl = await Auth.getS3SignedToken(
    params,
    auth.state.auth.accessToken
  )
  return await axios.put(signedUrl, base64str, {
    onUploadProgress: function (progressEvent) {
      let {loaded, total} = progressEvent
      onProgress({
        loaded,
        total,
        percent: (loaded / total) * 100,
      })
    },
    headers: {
      'Content-Type': contentType,
    },
  })
}

export default {
  uploadFile,
  uploadImageInBase64Str,
}
