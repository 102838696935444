var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "620px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-titledialog": "" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TITLE_POPUP"
                      )
                    ) + " "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.closePopup()
                        },
                      },
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _vm.sentStudent
                        ? _c("v-checkbox", {
                            attrs: {
                              label: _vm.$vuetify.lang.t(
                                "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.SELECT_ALL_STUDENTS"
                              ),
                            },
                            model: {
                              value: _vm.selected,
                              callback: function ($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected",
                            },
                          })
                        : _vm._e(),
                      !_vm.selected && _vm.sentStudent
                        ? _c("BaseAutocomplete", {
                            attrs: {
                              label: _vm.$vuetify.lang.t(
                                "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_PICK_STUDENT"
                              ),
                              placeholder: _vm.$vuetify.lang.t(
                                "$vuetify.USER_MANAGE.STUDENT_LIST.TITLE"
                              ),
                              items: _vm.students,
                              "item-text": "user.name",
                              "item-value": "user.id",
                              returnObject: false,
                              multiple: "",
                              "small-chips": "",
                              "deletable-chips": "",
                              rules: [_vm.$rules.minLength(1)],
                            },
                            model: {
                              value: _vm.receivers,
                              callback: function ($$v) {
                                _vm.receivers = $$v
                              },
                              expression: "receivers",
                            },
                          })
                        : _vm._e(),
                      _c("BaseInput", {
                        staticClass: "mt-2",
                        attrs: {
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.NOTIFICATION_TITLE_INPUT"
                          ),
                          rules: [_vm.$rules.required],
                        },
                        model: {
                          value: _vm.title,
                          callback: function ($$v) {
                            _vm.title = $$v
                          },
                          expression: "title",
                        },
                      }),
                      _c("BaseTextArea", {
                        staticClass: "mt-2",
                        attrs: {
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.SHORT_CONTENT_INPUT"
                          ),
                          row: 2,
                          rules: [_vm.$rules.required],
                        },
                        model: {
                          value: _vm.notification,
                          callback: function ($$v) {
                            _vm.notification = $$v
                          },
                          expression: "notification",
                        },
                      }),
                      _c("BaseInput", {
                        staticClass: "mt-2",
                        attrs: {
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LINK_WEB_INPUT"
                          ),
                        },
                        model: {
                          value: _vm.linkWeb,
                          callback: function ($$v) {
                            _vm.linkWeb = $$v
                          },
                          expression: "linkWeb",
                        },
                      }),
                      _c("BaseInput", {
                        staticClass: "mt-2",
                        attrs: {
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LINK_MOBILE_APP_INPUT"
                          ),
                        },
                        model: {
                          value: _vm.linkMobile,
                          callback: function ($$v) {
                            _vm.linkMobile = $$v
                          },
                          expression: "linkMobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "py-4" },
                [
                  _c("BaseButton", {
                    attrs: {
                      outlined: "",
                      text: _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_IMPORT_FILE_HTML"
                      ),
                    },
                    on: { click: _vm.importHtml },
                  }),
                  _c("v-spacer"),
                  _c("BaseButton", {
                    attrs: {
                      text: !_vm.sentStudent
                        ? `${_vm.$vuetify.lang.t(
                            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.SEND_NOTIFI_TO"
                          )}`
                        : _vm.selected
                        ? _vm.$vuetify.lang.t(
                            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_SEND_ALL_GROUP"
                          )
                        : `${_vm.$vuetify.lang.t(
                            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_SEND"
                          )} ${_vm.receivers.length} ${_vm.$vuetify.lang.t(
                            "$vuetify.USER_MANAGE.STUDENT_LIST.TITLE"
                          )}`,
                    },
                    on: { click: _vm.sendNotification },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ErrorNotificationPopup", {
        ref: "error-notification",
        attrs: { type: "notification" },
      }),
      _c("SentNotificationPopup", {
        ref: "send-notificaton",
        attrs: { type: "notification" },
      }),
      _c("input", {
        ref: "input-html-file",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: ".html" },
        on: { change: _vm.handleFileSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }