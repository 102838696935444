var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { attrs: { id: `toolbar-container${_vm.id}` } }, [
        _vm._m(0),
        _vm._m(1),
        _c("span", { staticClass: "ql-formats" }, [
          _c(
            "select",
            { staticClass: "ql-color" },
            _vm._l(
              _vm.$helpers.QUILL_DEFAULT_PICKER_COLORS,
              function (clr, clrIdx) {
                return _c("option", { key: clrIdx, domProps: { value: clr } })
              }
            ),
            0
          ),
          _c(
            "select",
            { staticClass: "ql-background" },
            _vm._l(
              _vm.$helpers.QUILL_DEFAULT_PICKER_COLORS,
              function (clr, clrIdx) {
                return _c("option", { key: clrIdx, domProps: { value: clr } })
              }
            ),
            0
          ),
        ]),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
        _vm._m(5),
        _vm._m(6),
        _c("span", { staticClass: "ql-formats" }, [
          _c(
            "button",
            {
              staticClass: "d-flex items-center",
              on: {
                click: function ($event) {
                  return _vm.actionTables("insert")
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "20", color: "#000000DE" } }, [
                _vm._v("mdi-table"),
              ]),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "d-flex items-center",
              class: `ql-html${_vm.id}`,
              staticStyle: { width: "fit-content" },
            },
            [_c("span", [_vm._v("HTML")])]
          ),
        ]),
        _c("span", { staticClass: "ql-formats" }, [
          _c("button", { attrs: { id: `ql-math${_vm.id}` } }, [
            _c("div", {
              staticClass: "ql-math-icon",
              staticStyle: { width: "18px", height: "16px" },
            }),
          ]),
          _c("button", { attrs: { id: `ql-chemistry${_vm.id}` } }, [
            _c("div", {
              staticClass: "ql-chemistry-icon",
              staticStyle: { width: "18px", height: "16px" },
            }),
          ]),
          _c("span", {
            class: `ql-mathtype${_vm.id}`,
            staticStyle: { display: "none" },
            attrs: { id: `ql-mathtype${_vm.id}` },
          }),
        ]),
        _c("span", { staticClass: "ql-formats" }),
      ]),
      _c("div", {
        ref: "quill-editor",
        class: `quill-editor${_vm.id}` || "quill-editor__custom",
        style: `min-height: ${_vm.minHeight}px; max-height: ${_vm.maxHeight}; overflow-y: auto; height: calc(100% - ${_vm.customHeight}px)`,
        attrs: { id: `quill-editor${_vm.id}` },
      }),
      _c("DialogInputUrl", {
        ref: "dialog-url",
        on: { onSelect: (url) => _vm.embedLinkVideo(url) },
      }),
      _c(
        "BaseUploadDragger",
        {
          attrs: { type: "image" },
          on: {
            onUploadSuccess: (item) =>
              _vm.renderUploadedImage(item[0], "direct"),
          },
        },
        [_c("div", { ref: "uploadImage" })]
      ),
      _c(
        "BaseUploadDragger",
        {
          attrs: { type: "audio" },
          on: { onUploadSuccess: _vm.renderUploadedAudio },
        },
        [_c("div", { ref: "uploadAudio" })]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ql-formats" }, [
      _c("select", { staticClass: "ql-font" }),
      _c("span", { staticClass: "ql-format-group" }, [
        _c("select", { staticClass: "ql-size", attrs: { title: "Size" } }, [
          _c("option", { attrs: { value: "10px" } }, [_vm._v("10px")]),
          _c("option", { attrs: { value: "12px" } }, [_vm._v("12px")]),
          _c("option", { attrs: { value: "14px" } }, [_vm._v("14px")]),
          _c("option", { attrs: { value: "16px" } }, [_vm._v("16px")]),
          _c("option", { attrs: { value: "18px" } }, [_vm._v("18px")]),
          _c("option", { attrs: { value: "20px" } }, [_vm._v("20px")]),
          _c("option", { attrs: { value: "24px" } }, [_vm._v("24px")]),
          _c("option", { attrs: { value: "32px" } }, [_vm._v("32px")]),
          _c("option", { attrs: { value: "36px" } }, [_vm._v("36px")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ql-formats" }, [
      _c("button", { staticClass: "ql-bold" }),
      _c("button", { staticClass: "ql-italic" }),
      _c("button", { staticClass: "ql-underline" }),
      _c("button", { staticClass: "ql-strike" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ql-formats" }, [
      _c("button", { staticClass: "ql-script", attrs: { value: "sub" } }),
      _c("button", { staticClass: "ql-script", attrs: { value: "super" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ql-formats" }, [
      _c("button", { staticClass: "ql-header", attrs: { value: "1" } }),
      _c("button", { staticClass: "ql-header", attrs: { value: "2" } }),
      _c("button", { staticClass: "ql-blockquote" }),
      _c("button", { staticClass: "ql-code-block" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ql-formats" }, [
      _c("button", { staticClass: "ql-list", attrs: { value: "ordered" } }),
      _c("button", { staticClass: "ql-list", attrs: { value: "bullet" } }),
      _c("button", { staticClass: "ql-indent", attrs: { value: "-1" } }),
      _c("button", { staticClass: "ql-indent", attrs: { value: "+1" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ql-formats" }, [
      _c("button", { staticClass: "ql-direction", attrs: { value: "rtl" } }),
      _c("select", { staticClass: "ql-align" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ql-formats" }, [
      _c("button", { staticClass: "ql-link" }),
      _c("button", { staticClass: "ql-image" }),
      _c("button", { staticClass: "ql-video" }),
      _c("button", { staticClass: "ql-voice" }, [_vm._v("♫")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }