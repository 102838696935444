<template>
  <div class="element video-player">
    <video
      v-if="canPlay"
      ref="videoPlayer"
      class="video-js vjs-default-skin vjs-big-play-centered"
      preload="auto"
    />
  </div>
</template>
<script>
/* eslint-disable */
import get from 'lodash-es/get'
import videojs from 'video.js'
import qualityLevels from 'videojs-contrib-quality-levels'
import hlsQualitySelector from 'videojs-hls-quality-selector'
import 'video.js/dist/video-js.css'
const DEFAULT_EVENTS = [
  'loadedmetadata',
  'canplay',
  'canplaythrough',
  'durationchange',
  'volumechange',
  'play',
  'pause',
  'waiting',
  'playing',
  'ended',
  'error',
  'loadstart',
  'progress',
  'suspend',
  'abort',
  'emptied',
  'stalled',
  'loadeddata',
  'waiting',
  'seeking',
  'seeked',
  'ratechange',
  'resize',
  'timeupdate',
]
const POSTFIX_LGY = 'playlist.m3u8'
const POSTFIX_DRM = 'playlist_drm.m3u8'
const CDN_OLD_SEED = '256682996'
const CDN_NEW_SEED = '256166744'

export default {
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
    currentPlayTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      player: null,
      duration: 0,
      currentTime: 0,
      canLog: true,
      canPlay: true,
      isSavedLoadTimes: false,
    }
  },
  methods: {
    initVideo(resource) {
      let vm = this
      videojs.registerPlugin('qualityLevels', qualityLevels)
      videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector)
      const videoConfig = this.getVideoConfig(resource)
      const videoThumbnail = get(resource, 'origin.thumbnail', '')
      if (typeof videoThumbnail === 'string' && videoThumbnail.length) {
        videoConfig.poster = this.$utils.convertURL(
          this.$utils.combineLinkWithBucket(videoThumbnail)
        )
      }
      this.player = videojs(
        this.$refs.videoPlayer,
        videoConfig,
        function onPlayRead() {
          vm.bindEventVideoJS(this, DEFAULT_EVENTS)
        }
      )
    },
    getVideoConfig(resource) {
      const sourceLink = this.getSourceLink(resource)
      const postfix = this.getLinkPostfix(resource)
      const videoConfig = {
        preload: 'auto',
        autoplay: false,
        controls: true,
        responsive: true,
        aspectRatio: '16:9',
        playbackRates: [0.5, 1, 1.5],
        sources: [
          {
            src: `${sourceLink}/${postfix}`,
            type: 'application/x-mpegURL',
          },
        ],
        html5: {
          controlBar: {
            pictureInPictureToggle: false,
          },
          hls: {
            overrideNative: true, // add this line
          },
        },
        plugins: {
          qualityLevels: {},
          hlsQualitySelector: {},
        },
      }
      const videoThumbnail = get(resource, 'origin.thumbnail', '')
      if (typeof videoThumbnail === 'string' && videoThumbnail.length) {
        videoConfig.poster = this.$utils.convertURL(
          this.$utils.combineLinkWithBucket(videoThumbnail)
        )
      }
      return videoConfig
    },
    getSourceLink(resource) {
      let link = resource.link
      let cdnSeed = link.indexOf('meta.smil') < 0 ? CDN_NEW_SEED : CDN_OLD_SEED
      return `https://${cdnSeed}.e.cdneverest.net/${link}`
    },
    getLinkPostfix(resource) {
      const isDrm = get(resource, 'origin.isProtected', false)
      return isDrm ? POSTFIX_DRM : POSTFIX_LGY
    },
    bindEventVideoJS(player, events) {
      events.forEach((event) => {
        player.on(event, (data) => {
          this.handleEvent(event, data)
        })
      })
    },
    handleEvent(event) {
      switch (event) {
        case 'canplay': {
          this.duration = this.player.duration()
          return this.duration
        }
        case 'timeupdate': {
          if (!this.isSavedLoadTimes) {
            this.isSavedLoadTimes = true
            this.$emit('saveLoadTimes')
          }
          if (this.canLog) {
            this.$emit('log-progress')
            this.canLog = false
            setTimeout(() => {
              this.canLog = true
            }, this.timeInterval * 1000)
          }
          this.currentTime = this.player.currentTime()
          return this.currentTime
        }
        case 'error': {
          this.canPlay = false
          return this.canPlay
        }
        case 'ended': {
          this.player.currentTime(0)
        }
      }
    },
    pause() {
      this.player.pause()
    },
  },
  mounted() {
    this.initVideo(this.resource)
  },
  beforeDestroy() {
    this.isSavedLoadTimes = false
    if (this.player) {
      this.player.dispose()
    }
  },
}
</script>
<style lang="scss" scoped>
$big-play-width: 3em;
$big-play-height: 1.5em;
.element.video-player::v-deep {
  .video-js {
    width: 100%;
    padding-top: 56.25%;
  }
  video {
    max-height: 100%;
  }
  video[poster] {
    object-fit: cover;
  }
  .vjs-poster {
    background-size: cover;
    background-position: inherit;
  }
}
</style>
