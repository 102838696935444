var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      staticClass: "background-authen full-width full-height",
      attrs: { "align-center": "", "justify-center": "" },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs11: "", md9: "", lg8: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass:
                    "pureground-authen pa-8 d-flex flex-column justify-space-between primary--text",
                  attrs: { xs12: "", md6: "" },
                },
                [
                  _c("div", { staticClass: "d-flex justify-center" }, [
                    _c("img", {
                      staticClass: "mb-4 full-height",
                      attrs: {
                        src: "/admin-static/logo-izteach.svg",
                        width: "150",
                      },
                    }),
                  ]),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "headline font-weight-bold mb-2" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.AUTHENTICATION.TITLE_HELP"
                            )
                          ) + "?"
                        ),
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.AUTHENTICATION.LABEL_HOLINE"
                            )
                          ) +
                          " hotline: "
                      ),
                      _c("br"),
                      _vm._v(" 0366311292 ( Mr Huy ) "),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.AUTHENTICATION.LABEL_ADDRESS_EMAIL"
                            )
                          ) +
                          ": "
                      ),
                      _c("br"),
                      _vm._v(" support@izteach.vn "),
                    ]),
                  ]),
                ]
              ),
              _c("v-flex", [_c("router-view")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }