import Repository from '@/api/core'
import vendor from '@/store/modules/vendor'
import queryString from 'query-string'
const baseURL = process.env.VUE_APP_API_EXAM + '/submission'

const fetch = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + `?${query}`).fetch()
}

const fetchOne = id => {
	return Repository(baseURL + `/${id}`).fetch({result: true})
}
const fetchLast = params => {
	return Repository(baseURL + '/last-submission').fetch(params)
}
const create = params => {
	return Repository(baseURL).create(params)
}

const statistic = params => {
	return Repository(baseURL + '/statistic').fetch(params)
}

const analyticQuestion = params => {
	return Repository(baseURL + '/analytic-question').fetch(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const remove = (id) => {
	return Repository(baseURL).delete(id)
}

const submit = (id, params) => {
  return Repository(baseURL + '/submit').update(id, params)
}

const count = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + `/count?${query}`).fetch()
}

export default {
	count,
	fetch,
	fetchOne,
	create,
	update,
	fetchLast,
	statistic,
	analyticQuestion,
	remove,
	submit
}
