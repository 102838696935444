import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_LEARN + '/unit'

const count = params => {
  const query = queryString.stringify({
    ...params,
    vendorId: vendor.state.vendor.vendorId
  })
  return Repository(baseURL + '/count' + `?${query}`).fetch()
}

const fetch = (params) => {
  return Repository(baseURL).fetch(params)
}
const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const create = (params) => {
  return Repository(baseURL).create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const sendNoti = (id, params) => {
  return Repository(`${baseURL}/send-noti`).update(id, params)
}

const remove = (id) => {
  return Repository(baseURL).delete(id)
}

const assignTeacherToHomeworkUnit = (id, params) => {
  return Repository(baseURL + '/assign').update(id, params)
}

const removeAssignTeacherFromHomeworkUnit = (id, params) => {
  return Repository(baseURL + '/remove-assign').update(id, params)
}

const fetchAssignedHomeworkForTeacher = (params) => {
  const query = queryString.stringify({
    ...params,
    vendorId: vendor.state.vendor.vendorId
  })
  return Repository(baseURL + '/get-assign' + `?${query}`).fetch()
}

const fetchAssignedHomeworkForAdmin = (params) => {
  const query = queryString.stringify({
    ...params,
    vendorId: vendor.state.vendor.vendorId
  })
  return Repository(baseURL + '/list-assign' + `?${query}`).fetch()
}
const saveDraftUnit = (id, params) => {
  return Repository(baseURL + '/sync-draft').update(id, params)
}

export default {
  fetch,
  fetchOne,
  create,
  update,
  remove,
  count,
  sendNoti,
  assignTeacherToHomeworkUnit,
  removeAssignTeacherFromHomeworkUnit,
  fetchAssignedHomeworkForTeacher,
  fetchAssignedHomeworkForAdmin,
  saveDraftUnit
}