<template>
  <vue-dropzone
    ref="dropzone"
    id="dropzone"
    :include-styling="false"
    :useCustomSlot="true"
    :options="dropzoneOptions"
  >
    <slot></slot>
    <v-progress-linear
      class="mt-4"
      v-if="loading"
      :value="percent"
    ></v-progress-linear>
  </vue-dropzone>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  computed: {
    ...mapGetters({
      getEditedIndex: 'cinema/getEditedIndex',
      getUploadStatus: 'cinema/getUploadStatus',
      getMinimumWindowStatus: 'cinema/getMinimumWindowStatus',
    }),
  },
  props: {
    maxFilesize: {
      type: Number,
      default: 3750,
    },
    singleUploadFileSize: {
      type: Number,
      default: 2560,
    },
    type: String,
    maxFiles: {
      type: Number,
      default: 1,
    },
    uploadMultiple: Boolean,
    extendedParams: {
      type: Object,
      default: () => ({}),
    },
    relation: {
      type: Object,
      default: () => ({}),
    },
    isConfirmPopupUsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const vm = this
    return {
      loading: false,
      percent: 0,
      dropzoneOptions: {
        previewTemplate: '<div/>',
        url: this.uploadFileControl,
        addRemoveLinks: false,
        maxFiles: this.maxFiles,
        maxFilesize: this.maxFilesize,
        parallelUploads: this.maxFiles,
        acceptedFiles: this.acceptedFiles(),
        uploadMultiple: this.uploadMultiple,
        init: function () {
          this.on('maxfilesexceeded', function () {
            // vm.$message.error(
            //   vm.$vuetify.lang.t(
            //     '$vuetify.COURSE.BASE_UPLOAD_DRAGGER.MSG_FILE_UPLOAD_OVER_THE_SIZE',
            //     vm.maxFiles
            //   )
            // )
            this.removeAllFiles(true)
          })
          this.on('error', function (_, message) {
            vm.showReadableErrorMessage(message || '')
            this.removeAllFiles(true)
          })
        },
      },
      haveVideoOversize: false,
    }
  },
  created() {
    this.setUploadStatus(false)
  },
  methods: {
    ...mapActions({
      createDocument: 'document/createDocument',
      createHLSVideo: 'cinema/createHLSVideo',
      uploadQuestionViaFile: 'question/uploadQuestionViaFile',
      setFileEdited: 'cinema/setFileEdited',
      setUploadStatus: 'cinema/setUploadStatus',
    }),
    acceptedFiles() {
      if (this.type === 'pdf') {
        return 'application/pdf'
      } else if (this.type === 'video' || this.type === 'vimeo') {
        return '.mp4, .mov, .m4v, .mkv, .wmv'
      } else if (this.type === 'favicon') {
        return '.ico'
      } else if (this.type === 'sub') {
        return '.vtt'
      } else if (this.type === 'image') {
        return '.jpg, .jpeg, .png'
      } else if (this.type === 'audio') {
        return '.mp3'
      } else if (this.type === 'docx') {
        return '.docx'
      } else {
        return 'image/*,application/pdf,.zip, .rar, application/msword, .mp4, .mp3, .wav, .docx, .doc'
      }
    },
    progressHandler(percent) {
      this.percent = Math.floor(percent)
    },
    disabled() {
      this.$refs['dropzone'].disable()
    },
    enabled() {
      this.$refs['dropzone'].enable()
    },
    removeAllFiles() {
      this.$refs['dropzone'].removeAllFiles()
    },
    uploadFileControl(files) {
      if (!this.getUploadStatus) {
        this.$emit('onUpload', files)
      }
    },
    showReadableErrorMessage(message = '') {
      if (typeof message === 'string' && message.length) {
        const lowercaseMessage = message.toLowerCase()
        if (lowercaseMessage.indexOf('this type') > -1) {
          this.$message.error(
            this.$vuetify.lang.t(
              '$vuetify.COURSE.BASE_UPLOAD_DRAGGER.MSG_FORMAT_FILE_UPLOAD_FALSE'
            )
          )
        }
      }
    },
  },
  watch: {
    loading: {
      handler(val) {
        this.$emit('onChangeVisibilityProgress', val)
      },
      immediate: true,
    },
    percent: {
      handler(val) {
        this.$emit('onChangeProgress', val)
      },
      immediate: true,
    },
    getMinimumWindowStatus: {
      handler(val) {
        if (val === true && this.getUploadStatus === true) {
          this.isUploadDone = true
        }
      },
      immediate: true,
    },
  },
}
</script>
