import vi from '@/plugins/locale.vi.js'
import AUTHENTICATION from './vi/authentication'
import DASHBOARD from './vi/dashboard'
import COURSE from './vi/course'
import COURSE_CONTENT from './vi/courseContent'
import MANAGE_FINANCE from './vi/manageFinance'
import MENU_SETTING from './vi/menuSetting'
import SITE_BUILDER from './vi/siteBuilder'
import ROUTE from './vi/route'
import BLOG from './vi/blog'
import SYSTEM_SETTING from './vi/systemSetting'
import MY_ACCOUNT from './vi/myAccount'
import USER_MANAGE from './vi/userManage'
import INPUT from './vi/input'
import DEFAULT_DATA from './vi/defaultData'

export default {
  AUTHENTICATION,
  DASHBOARD,
  COURSE,
  COURSE_CONTENT,
  MANAGE_FINANCE,
  MENU_SETTING,
  SITE_BUILDER,
  ROUTE,
  BLOG,
  SYSTEM_SETTING,
  MY_ACCOUNT,
  USER_MANAGE,
  INPUT,
  DEFAULT_DATA,
  BUTTON_SEARCH: "Tìm kiếm",
  //
  LABEL_PAGE: "Trang",
  LABEL_TO: "Từ",
  LABEL_SUM: "tổng",
  TEXT_SUM: "Tổng",
  LABEL_NOT_YET: "chưa có",
  //
  TITLE_HOME_PAGE: "Trang chủ",
  BUTTON_CANCEL: "Huỷ bỏ",
  BUTTON_CONFIRM: "Xác nhận",
  TOOLTIP_FILE_NOT_HEADER: "Tệp không có tiêu đề",
  //
  STUDENTS: "Học viên",
  TITLE_TOPIC: "Chủ đề",
  TEXT_GROUP: "nhóm",
  TEXT_QUESTION: "câu",
  TEXT_QUESTION_CHILD: "câu hỏi con",

  TEXT_NOT_NAME: "Không có tên",
  TEXT_NOT_EMAIL: "Không có email",
  TEXT_HOUR: "tiếng",
  TEXT_MINUTE: "phút",
  TEXT_SECOND: "giây",
  LABEL_VIDEO: "Video",
  LABEL_PDF_SLIDE: "Tài liệu PDF",
  LABEL_TEST: "Bài kiểm tra (Đề tự tạo)",
  LABEL_EXERCISE: "Bài kiểm tra (Đề Có Sẵn)",
  LABEL_HOMEWORK: "Bài tập về nhà",
  LABEL_MATRIX: "Bài kiểm tra (Ma Trận Đề)",
  LABEL_AUDIO:"Audio",
  LABEL_READING_TEST:"Bài kiểm tra (Reading test)",
  LABEL_LISTENING_TEST:"Bài kiểm tra (Listening test)",

  REJECTED: "Từ chối",
  SUCCESS: "Hoàn tất",
  PENDING: "Chờ",
  FAILED: "Thất bại",
  RETURNED: "Trả",
  LABEL_TRANSFER: "Chuyển khoản",
  LABEL_DIRECT_PAY: "Tiền mặt",
  LABEL_VN_PAY: "VNPAY",
  LABEL_ADMIN_CREATED: "Admin tạo",
  LABEL_ACTIVATION_CODE: "Mã kích hoạt",

  TEXT_WHEN: "lúc ",
  TEXT_ALMOST: "Gần {0} tiếng",
  TEXT_MORE_THAN: "Hơn {0} tiếng",

  LABEL_TEXT_TEST: "Thoát xem thử",
  //BASELOADINGALLSCREEN


  HINT_MARK_NOTE: "Điểm không được lẻ quá 3 chữ số",
  HINT_TIME_FOR_QUESTION: "Thời gian cho từng câu phải tồn tại và không được lẻ",
  HINT_UNDER_QUESTION_IN_QUESTION_BANK: "không đủ số lượng câu trong ngân hàng đề",
  MSG_FILE_FOMAT_FALSE: "Sai định dạng file, vui lòng kiểm tra lại hoặc thử lại bằng template mẫu!",

  //EMPTY_DATA
  TITLE_EMPTY_DATA: "Chưa tồn tại dữ liệu",
  SUBTITLE_EMPTY_DATA: "Vui lòng tạo mới dữ liệu để hiển thị trên danh sách này",
  BUTTON_EMPTY_DATA: "Tạo dữ liệu",
  LABEL_NAME_CATEGORY_CHILD: "Tên danh mục con",
  PLACEHOLDER_INPUT_NAME_CATEGORY_CHILD: "Nhập tên danh mục con muốn thêm",
  MSG_FILTERING_CONTACT: "Đang lọc liên hệ ...",

  TEXT_YES: "Có",
  TEXT_NO: "Không",
  MSG_NOTICATION_NOT_DOWNLOAD_FILE: "Hiện không thể tải được file. Vui lòng xoá cache hoặc sử dụng trình duyệt ẩn danh",

  BUTTON_POST: "Đăng",
  BUTTON_VIEW_ACCEPT: "Xem và duyệt",
  LABEL_DATE: "ngày",
  BUTTON_COPY: "Sao chép",
  INFO_GENERAL: "Thông tin chung",
  LABEL_ON: "trên",
  MSG_ERROR_SYSTEM: "Lỗi hệ thống",
  ...vi
}